import React, { FC, useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { IconTooltip } from 'wix-ui-tpa';
import WUTMoreIcon from 'wix-ui-icons-common/on-stage/More';

import { DataHook, Position } from '../../../types';
import { MigratedPopover } from '../Popover';
import styles from './MoreButton.scss';
import {
  DEFAULT_HIDE_DELAY,
  DEFAULT_SHOW_DELAY,
} from '../../../constants/tooltip';

export interface MoreButtonProps {
  alignment: Position;
  isMobile: boolean;
  isRtlLocale: boolean;
  isDisabled?: boolean;
  dynamicPositioning?: boolean;
}

const MoreButton: FC<MoreButtonProps> = ({
  alignment,
  isMobile,
  isRtlLocale,
  isDisabled,
  dynamicPositioning,
  children,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <IconTooltip
      data-hook={DataHook.MoreActionsTooltip}
      icon={
        <MigratedPopover
          dataHook={DataHook.MoreActionsCTA}
          alignment={alignment}
          dynamicPositioning={dynamicPositioning}
          content={children}
          disabled={isDisabled}
          onOpen={() => setPopoverOpen(true)}
          onClose={() => setPopoverOpen(false)}
          isOpen={isPopoverOpen}
        >
          <WUTMoreIcon className={styles.moreIcon} />
        </MigratedPopover>
      }
      message={t('more-button.tooltip')}
      showTooltip={isTooltipOpen && !isPopoverOpen && !isMobile && !isDisabled}
      tooltipProps={{
        placement: isRtlLocale ? Position.Right : Position.Left,
        showDelay: DEFAULT_SHOW_DELAY,
        hideDelay: DEFAULT_HIDE_DELAY,
      }}
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
      disabled={isDisabled}
    />
  );
};

export default MoreButton;
