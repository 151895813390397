import React, { FC } from 'react';
import { RoleAction } from '@wix/members-domain-ts';

import { AdditionalAction, DataHook, Position } from '../../../types';
import MoreButton from '../MoreButton';
import {
  PopoverContent,
  OnAdditionalAction,
  OnRoleAction,
} from './PopoverContent';

interface MemberMoreButtonProps {
  containerClass: string;
  additionalActions: AdditionalAction[];
  rolesActions: RoleAction[];
  alignment: Position;
  isMobile: boolean;
  isRtlLocale: boolean;
  isDisabled?: boolean;
  dynamicPositioning?: boolean;
  onAdditionalAction: OnAdditionalAction;
  onRoleAction: OnRoleAction;
}

const MemberMoreButton: FC<MemberMoreButtonProps> = ({
  containerClass,
  additionalActions,
  rolesActions,
  alignment,
  isMobile,
  isRtlLocale,
  isDisabled,
  dynamicPositioning = false,
  onAdditionalAction,
  onRoleAction,
}) => {
  if (!rolesActions.length && !additionalActions.length) {
    return null;
  }

  return (
    <div data-hook={DataHook.MoreActionsWrapper} className={containerClass}>
      <MoreButton
        dynamicPositioning={dynamicPositioning}
        alignment={alignment}
        isRtlLocale={isRtlLocale}
        isMobile={isMobile}
        isDisabled={isDisabled}
      >
        <PopoverContent
          additionalActions={additionalActions}
          rolesActions={rolesActions}
          isMobile={isMobile}
          onAdditionalAction={onAdditionalAction}
          onRoleAction={onRoleAction}
        />
      </MoreButton>
    </div>
  );
};

export default MemberMoreButton;
